document.addEventListener('scroll', function() {
  // Get the navbar element
  var navbar = document.getElementById('navbar');
  var scrollToTopBtn = document.getElementById('navbutton');

  // Get the offsetTop of each section
  var backOffsetTop = document.getElementById('back').offsetTop;
  var mainOffsetTop = document.getElementById('mainweb').offsetTop;

  // Get the current scroll position
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  // Check if the scroll position is within the specified range for each section
  if (scrollTop >= backOffsetTop && scrollTop < mainOffsetTop) {
    navbar.style.backgroundColor = 'rgba(255,192,23,255)'; // Change navbar color to yellow
    navbar.style.transition = 'background-color 0.5s ease-in'; // Add transition with ease-in effect
    scrollToTopBtn.style.display = 'none';
  } else {
    navbar.style.backgroundColor = 'white'; // Change navbar color to white
    navbar.style.transition = 'background-color 0.5s ease-out'; // Add transition with ease-out effect
    scrollToTopBtn.style.display = 'flex';
    scrollToTopBtn.style.transition = 'background-color 0.5s ease-out';
  }
});


const words = ['Creativity.', 'Ideation.', 'Product.', 'Agile.', 'Analytics.', 'Zero to One.'];
const wordDisplay = document.querySelector('.word');

function showWord() {
	// Select a random word from the array
	const word = words[Math.floor(Math.random() * words.length)];

	// Display the word with ease-in-out transition
	wordDisplay.textContent = word;
	wordDisplay.style.opacity = 1;
	wordDisplay.style.transition = 'opacity 0.5s ease-in-out';

	// Wait for x seconds before transitioning to the next word
	setTimeout(() => {
		wordDisplay.style.opacity = 0;
		setTimeout(showWord, 500);
	}, 4000);
}

showWord();

const pElement = document.getElementById("spans"); // get the <pre> element

const chars = pElement.innerText.split(""); // split the text content into an array of characters

const spans = chars.map(char => `<span>${char}</span>`); // wrap each character in a <span> element

pElement.innerHTML = spans.join(""); // join the <span> elements back into a string and set it as the new HTML content of the <pre> element

const letters = document.querySelectorAll('#random-letters span');

function getRandomOpacity() {
  return Math.floor(Math.random() * 2);
}

function toggleLetterOpacity() {
  const randomIndex = Math.floor(Math.random() * letters.length);
  const letter = letters[randomIndex];
  letter.style.opacity = getRandomOpacity();
}

setInterval(() => {
  toggleLetterOpacity();
}, 100);



// Get all elements with class="tab"
const tabLinks = document.querySelectorAll(".tab");
const activeTab = document.querySelector(".tab.active");

// Position the arrow initially
const arrow = document.createElement("div");
arrow.classList.add("tab-arrow");
document.querySelector(".tabs").appendChild(arrow);
arrow.style.left = activeTab.offsetLeft + (activeTab.offsetWidth) / 2 + "px";

// Loop through the tab links and add the click event listener to each one
tabLinks.forEach(function(tabLink) {
  tabLink.addEventListener("click", function(evt) {
    // Declare variables
    const tabName = this.dataset.tabName;
    const tabContent = document.querySelectorAll(".tab-content");

    // Check if the clicked tab is already active
    if (this.classList.contains("active")) {
      // Hide the corresponding content
      document.getElementById(tabName).style.display = "none";
      // Remove the active class from the clicked tab
      this.classList.remove("active");
    } else {
      // Loop through the tab content and hide them
      tabContent.forEach(function(content) {
        content.style.display = "none";
      });

      // Remove the active class from all tab links
      tabLinks.forEach(function(link) {
        link.classList.remove("active");
      });

      // Show the current tab and add an "active" class to the button that opened the tab
      document.getElementById(tabName).style.display = "block";
      this.classList.add("active");

      // Move the arrow to the active tab
      const activeTab = document.querySelector(".tab.active");
      arrow.style.left = activeTab.offsetLeft + (activeTab.offsetWidth) / 2 + "px";
    }
  });
});

// Set the default tab to the first tab
document.getElementById("tab1").style.display = "block";
document.querySelector(".tab").classList.add("active");


// Get the current year
const currentYear = new Date().getFullYear();
        
// Update the footer copyright text
document.addEventListener('DOMContentLoaded', function() {
    const footerCopyright = document.getElementById('footer-copyright');
    footerCopyright.textContent = `© ${currentYear} Ofri Yehuda`;
  });
